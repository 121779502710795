.fc-timegrid-slot-minor {
  border-top-style: none !important;
}

.fc-timegrid-slot-label {
  vertical-align: bottom;
  bottom: 14px;
  position: relative;
}

.fc-theme-standard td,
.fc-theme-standard th {
  /*border: none !important;*/
}
.fc-scrollgrid {
  border: none !important;
}

.fc-timegrid-slot-minor {
  border-bottom: 2px dashed #eaeaea !important;
}

.fc-timegrid-slot-label {
  border-bottom: none !important;
  border-top: none !important;
  border-right-color: #ffffff !important;
}

.fc-col-header-cell {
  border: none !important;
  padding-bottom: 16px !important;
}

.fc-timegrid-now-indicator-arrow {
  border-color: #37dc58 !important;
}

.fc-timegrid-now-indicator-line {
  border-color: #37dc58 !important;
}

.fc-timegrid-axis {
  border: none !important;
}

.fc-timegrid-col.fc-day-today {
  background-color: rgba(41, 189, 82, 0.02) !important;
}

.fc {
  /*height: 65vh !important;*/
}

table {
  border: none;
  border-collapse: collapse;
}
table td {
  border-left: none !important;
}
table td:first-child {
  border-left: none;
}
